import Typewriter from "typewriter-effect";
import "./App.css";

import react from "./assets/react.svg";
import reactnative from "./assets/react-native-1.svg";
import nextjs from "./assets/next-js.svg";
import html from "./assets/html-1.svg";
import css from "./assets/css-3.svg";
import js from "./assets/logo-javascript.svg";
import ts from "./assets/typescript.svg";
import tailwindcss from "./assets/tailwind-css-2.svg";
import redux from "./assets/redux.svg";
import reactquery from "./assets/reactquery.webp";

import java from "./assets/java-4.svg";
import python from "./assets/python-5.svg";
import kotlin from "./assets/kotlin-2.svg";
import spring from "./assets/spring-3.svg";
import go from "./assets/go-8.svg";
import nodejs from "./assets/nodejs-3.svg";
import express from "./assets/expressjs.webp";
import ruby from "./assets/ruby.svg";
import supabase from "./assets/supabase-icon.svg";
import redis from "./assets/redis.svg";

import linux from "./assets/linux-tux-1.svg";
import bash from "./assets/bash-icon.svg";
import docker from "./assets/docker-4.svg";
import postgre from "./assets/postgresql.svg";
import mongo from "./assets/mongodb-icon-1.svg";
import firebase from "./assets/firebase-1.svg";
import git from "./assets/git-icon.svg";
import postman from "./assets/postman.svg";
import vscode from "./assets/vsc.svg";

import { useEffect, useState } from "react";

const iconWidth = 40;
const iconHeight = 40;

const logos = {
  frontend: {
    html: "html",
    css: "css",
    js: "js",
    ts: "ts",
    tailwindcss: "tailwindcss",
    react: "react",
    reactnative: "reactnative",
    nextjs: "nextjs",
  },
  backend: {
    java: "java",
    kotlin: "kotlin",
    spring: "spring",
    nodejs: "nodejs",
    express: "express",
    python: "python",
  },
};

function App() {
  const [nameFont, setNameFont] = useState("inter");

  useEffect(() => {
    //alternate between fonts
    const fonts = [
      "monaco",
      "inter",
      "spacemono",
      "sans",
      "montserrat",
      "menlo",
      "consolas",
    ];
    let i = 0;
    const interval = setInterval(() => {
      setNameFont(fonts[i]);
      i = (i + 1) % fonts.length;
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative">
      <div className="absolute w-full h-full bg-black/65 backdrop-blur-md animate-saturate "></div>
      <div className="absolute w-full h-full bg-gradient-to-b from-black/10 to-white/40"></div>
      <main className="flex flex-col gap-y-10 w-full px-10 pt-4 pb-2 drop-shadow-xl bg-opacity-10 rounded-xl xl:w-[60%] mx-auto">
        <h1 className="text-4xl font-bold text-center mt-4">
          <Typewriter
            options={{
              strings: ["Jaime Saramago"],
              autoStart: true,
              delay: 100,
              loop: true,
            }}
          />
        </h1>
        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>
        <section className="text-lg flex flex-col gap-5 text-justify self-center antialiased font-medium ">
          <p>
            I hold a MSc degree in computer science and engineering and have a
            passion for technology and innovation. I have a solid academic
            background in software development and a keen interest in the latest
            technologies and full-stack development. I am always looking for new
            challenges and opportunities to learn and grow.
          </p>
          <p>
            I have worked on a variety of projects, spanning from web
            applications to mobile apps. I am always looking for new ways to
            apply my skills and knowledge to solve real-world problems.
          </p>
        </section>
        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>

        <section className="flex flex-col gap-5 text-justify">
          <h3 className="text-3xl font-extrabold drop-shadow-3xl">
            <span className="underline">Experience</span>
          </h3>
          <div>
            <h4 className="text-2xl">Software Engineer</h4>
            <p className="text-lg">Company Name</p>
            <p className="text-lg">Location</p>
            <p className="text-lg">Date</p>
            <p>
              lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            </p>
          </div>
        </section>
        <section className="flex flex-col gap-5 text-justify">
          <h3 className="text-3xl font-extrabold drop-shadow-3xl underline">
            Projects
          </h3>
        </section>
        <section className="font-normal flex flex-col gap-5">
          <h3 className="text-3xl font-extrabold drop-shadow-3xl">
            <span className="underline">Technologies</span>
          </h3>
          <p className="font-medium text-lg">
            These are some of the languages, platforms and technologies I often
            use or have knowledge of.
          </p>
          <div className="flex flex-col gap-y-5 justify-between flex-wrap">
            <div>
              <code className="text-2xl font-light">Frontend</code>
              <div className="flex flex-row gap-5 items-center flex-wrap my-3 select-none">
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={html}
                  alt="html logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={css}
                  alt="css logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={js}
                  alt="js logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={ts}
                  alt="ts logo"
                />

                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={tailwindcss}
                  alt="tailwindcss logo"
                />

                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={react}
                  alt="react logo"
                />
                <img
                  width={iconWidth + 15}
                  height={iconHeight + 15}
                  src={reactnative}
                  alt="reactnative logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={nextjs}
                  alt="nextjs logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={redux}
                  alt="redux logo"
                />
                <img
                  width={iconWidth}
                  height={iconHeight}
                  src={reactquery}
                  alt="reactquery logo"
                />
              </div>
            </div>
            <div>
              <code>
                <code className="text-2xl font-light">Backend</code>
                <div className="flex flex-row items-center gap-5 flex-wrap my-3">
                  <img
                    src={java}
                    alt="java logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={go}
                    alt="go logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={kotlin}
                    alt="kotlin logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={spring}
                    alt="spring logo"
                    width={iconWidth}
                    height={iconHeight}
                    className="bg-white rounded-full"
                  />
                  <img
                    src={nodejs}
                    alt="nodejs logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={express}
                    alt="express logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={python}
                    alt="python logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={ruby}
                    alt="ruby logo"
                    width={iconWidth}
                    height={iconHeight}
                  />

                  <img
                    src={supabase}
                    alt="supabase logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={redis}
                    alt="redis logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                </div>
              </code>
            </div>
            <div>
              <code>
                <h4 className="text-2xl font-light">Tools / OS</h4>
                <div className="flex flex-row items-center gap-5 flex-wrap my-3">
                  <img
                    src={linux}
                    alt="linux logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={bash}
                    alt="bash logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={docker}
                    alt="docker logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={postgre}
                    alt="postgre logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={mongo}
                    alt="mongo logo"
                    width={iconWidth - 10}
                    height={iconHeight - 10}
                  />
                  <img
                    src={firebase}
                    alt="firebase logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={git}
                    alt="git logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={postman}
                    alt="postman logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                  <img
                    src={vscode}
                    alt="vscode logo"
                    width={iconWidth}
                    height={iconHeight}
                  />
                </div>
              </code>
            </div>
          </div>
        </section>
        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>

        <section className="font-normal flex flex-col gap-5 text-justify">
          <p className="gap-5 text-lg md:text-xl text-justify self-center antialiased font-semibold">
            If I&apos;m not up to anything, chances are I&apos;m either:
            {/* gallery here with hobbies and etc. */}
          </p>
        </section>

        <div className="h-1 w-full border-t-2 rounded-lg border-white/90 shadow-xl"></div>

        <footer className="text-sm text-center font-bold">
          <span>&copy; Jaime Saramago 2024</span>
        </footer>
      </main>
    </div>
  );
}

export default App;
